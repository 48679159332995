const ded = '<h2>Условия обслуживания</h2>\n' +
  '<p>Эти Условия обслуживания регулируют ваше использование веб-сайта, расположенного по адресу https://dressdown.ai, и любых связанных с ним услуг, предоставляемых dressdown.ai.</p>\n' +
  '<p>Посещая https://dressdown.ai, вы соглашаетесь соблюдать эти Условия обслуживания и придерживаться всех применимых законов и нормативных актов. Если вы не согласны с этими Условиями обслуживания, вам запрещается использовать или получать доступ к этому веб-сайту или использовать любые другие услуги, предоставляемые dressdown.ai.</p>\n' +
  '<p>Мы, dressdown.ai, оставляем за собой право пересматривать и изменять любые из этих Условий обслуживания по нашему усмотрению. При внесении изменений мы обновим эту страницу. Любые изменения в этих Условиях обслуживания вступят в силу немедленно с даты публикации.</p>\n' +
  '<p>Эти Условия обслуживания были обновлены в последний раз 25 апреля 2024 года.</p>\n' +
  '\n' +
  '<h2>Информация о компании</h2>\n' +
  '<p>Веб-сайт dressdown.ai (далее именуемый как «Сайт») является собственностью и управляется компанией DATATEH LTD. Эта организация, далее именуемая как «Компания», «мы», «нас» или «наш», зарегистрирована в Англии. Наш основной зарегистрированный офис находится по адресу: 20 Wenlock Road, London, England, N1 7GU. Вы можете связаться с нами, написав по вышеуказанному адресу или отправив электронное письмо на адрес support@dressdown.ai.</p>\n' +
  '\n' +
  '<h2>Описание услуги</h2>\n' +
  '<p>dressdown.ai — это веб-платформа, использующая искусственный интеллект для генерации изображений для взрослой аудитории. Наша услуга позволяет пользователям настраивать изображения по заданным параметрам, таким как возраст, тип телосложения и качество. Ответственность за все сгенерированные изображения полностью ложится на пользователя. Кроме того, наша платформа предлагает ассортимент контента от моделей, доступного для покупки, при этом транзакции обрабатываются безопасно через доверенных третьих сторон, занимающихся обработкой платежей. В соответствии с нашей приверженностью к конфиденциальности пользователей, мы не храним и не отслеживаем данные пользователей или сгенерированные изображения.</p>\n' +
  '\n' +
  '<h2>Ограничения использования</h2>\n' +
  '<p>Используя этот веб-сайт, вы гарантируете от вашего имени, от имени ваших пользователей и других сторон, которые вы представляете, что вы не будете:</p>\n' +
  '<p>1. изменять, копировать, создавать производные работы, декомпилировать или выполнять обратное проектирование любых материалов и программного обеспечения, содержащихся на этом веб-сайте;</p>\n' +
  '<p>2. удалять какие-либо авторские права или другие указания собственности с любых материалов и программного обеспечения на этом веб-сайте;</p>\n' +
  '<p>3. передавать материалы другому лицу или «зеркалить» материалы на любом другом сервере;</p>\n' +
  '<p>4. сознательно или небрежно использовать этот веб-сайт или любые его связанные услуги таким образом, который нарушает или нарушает наши сети или любую другую услугу, предоставляемую dressdown.ai;</p>\n' +
  '<p>5. использовать этот веб-сайт или его связанные услуги для передачи или публикации каких-либо оскорбительных, непристойных, неприемлемых, мошеннических или незаконных материалов;</p>\n' +
  '<p>6. использовать этот веб-сайт или его связанные услуги в нарушение любых применимых законов или нормативных актов;</p>\n' +
  '<p>7. использовать этот веб-сайт в сочетании с отправкой несанкционированной рекламы или спама;</p>\n' +
  '<p>8. собирать, собирать или накапливать данные пользователей без их согласия; или</p>\n' +
  '<p>9. использовать этот веб-сайт или его связанные услуги таким образом, который может нарушать права на конфиденциальность, интеллектуальную собственность или другие права третьих сторон.</p>\n' +
  '\n' +
  '<h2>Интеллектуальная собственность</h2>\n' +
  '<p>Интеллектуальная собственность на материалы, содержащиеся на этом веб-сайте, принадлежит или лицензирована dressdown.ai и защищена применимыми законами об авторских правах и товарных знаках. Мы предоставляем нашим пользователям разрешение на загрузку одной копии материалов для личного, некоммерческого временного использования.</p>\n' +
  '<p>Это представляет собой предоставление лицензии, а не передачу права собственности. Эта лицензия автоматически прекращается, если вы нарушите любое из этих ограничений или Условия обслуживания, и может быть прекращена dressdown.ai в любое время.</p>\n' +
  '\n' +
  '<h2>Контент, созданный пользователями</h2>\n' +
  '<p>dressdown.ai отказывается от какой-либо ответственности за использование контента, созданного пользователями на нашем веб-сайте. Наши услуги позволяют пользователям генерировать изображения через свободный процесс выбора, указывая параметры возраста, типа телосложения и качества. Однако важно отметить, что мы не храним, не отслеживаем и не несем ответственности за эти изображения, созданные пользователями. Пользователь, создающий контент, сохраняет полное право собственности и несет полную ответственность за его использование или распространение, а также за любые возможные последствия этого.</p>\n' +
  '\n' +
  '<h2>Возрастные требования и обязанности пользователей</h2>\n' +
  '<p>Пользователи должны соответствовать минимальному возрастному требованию 18 лет или возрасту совершеннолетия в их юрисдикции, в зависимости от того, что больше, чтобы получить доступ и использовать наш веб-сайт. Взаимодействуя с нашими услугами, вы подтверждаете, что соответствуете этим возрастным требованиям.</p>\n' +
  '<p>Мы доверяем нашим пользователям в создании изображений ответственно. Вам запрещается создавать изображения лиц без их явного согласия или лиц, которые являются несовершеннолетними. Любое использование наших услуг для нарушения прав других, участия в незаконной деятельности или осуществления запрещенных действий не будет терпимо.</p>\n' +
  '\n' +
  '<h2>Покупки и варианты оплаты</h2>\n' +
  '<p>На dressdown.ai мы предлагаем различные планы оплаты, предназначенные для улучшения пользовательского опыта, при этом каждый план предоставляет определенные функции и кредиты:</p>\n' +
  '<p>- <strong>15 EUR</strong>: 30 генераций.</p>\n' +
  '<p>- <strong>25 EUR</strong>: 60 генераций.</p>\n' +
  '<p>- <strong>35 EUR</strong>: 90 генераций.</p>\n' +
  '<p>- <strong>45 EUR</strong>: 120 генераций.</p>\n' +
  '<p>- <strong>99 EUR</strong>: 270 генераций.</p>\n' +
  '<p>Все планы включают быструю генерацию и изображения в HD качестве. Оплата производится одной транзакцией, автоматическое продление не предусмотрено. Пользователи должны ознакомиться с особенностями и ограничениями выбранного плана.</p>\n' +
  '\n' +
  '<h2>Ответственность</h2>\n' +
  '<p>Наш веб-сайт и материалы на нем предоставляются на условиях «как есть». В максимальной степени, разрешенной законом, dressdown.ai не дает никаких гарантий, явных или подразумеваемых, и настоящим отказывается от всех остальных гарантий, включая, но не ограничиваясь, подразумеваемые гарантии или условия товарного состояния, пригодности для конкретной цели или не нарушения интеллектуальной собственности или других прав.</p>\n' +
  '<p>Ни при каких обстоятельствах dressdown.ai или ее поставщики не несут ответственности за любые косвенные убытки, понесенные вами или любой третьей стороной в результате использования или невозможности использования этого веб-сайта или материалов на нем, даже если dressdown.ai или уполномоченный представитель был уведомлен устно или письменно о возможности таких убытков.</p>\n' +
  '<p>В контексте этого соглашения «косвенные убытки» включает любые косвенные убытки, косвенные убытки, реальные или ожидаемые убытки от прибыли, потерю выгоды, потерю доходов, потерю бизнеса, потерю репутации, потерю возможности, потерю сбережений, потерю репутации, потерю использования и/или потерю или порчу данных, независимо от того, основаны ли они на законе, контракте, справедливости, правонарушении (включая небрежность), возмещении ущерба или ином.</p>\n' +
  '<p>Поскольку некоторые юрисдикции не допускают ограничений на подразумеваемые гарантии или ограничение ответственности за косвенные или случайные убытки, эти ограничения могут не применяться к вам.</p>\n' +
  '\n' +
  '<h2>Нулевая терпимость к откровенному контенту с участием несовершеннолетних</h2>\n' +
  '<p>Мы решительно против любого откровенного контента с участием несовершеннолетних. Мы активно мониторим наличие запрещенного контента с помощью передовых автоматизированных систем для создания безопасной среды для всех наших пользователей. Любые аккаунты, создающие или использующие откровенный контент с участием несовершеннолетних, будут немедленно заблокированы и сообщены в соответствующие органы в стране пользователя.</p>\n' +
  '\n' +
  '<h2>Точность материалов</h2>\n' +
  '<p>Материалы, размещенные на нашем веб-сайте, не являются исчерпывающими и предназначены только для общего информирования. dressdown.ai не гарантирует и не делает никаких заявлений относительно точности, вероятных результатов или надежности использования материалов на этом веб-сайте или в отношении таких материалов или любых ресурсов, связанных с этим веб-сайтом. Не следует полагаться исключительно на указанные материалы при принятии решений; мы советуем вам обратиться к другим, более полным источникам информации. Вы несете полную ответственность за строгое соблюдение материалов на нашем веб-сайте. Мы имеем право изменять информацию на нашем веб-сайте без предварительного уведомления, и пользователи несут ответственность за отслеживание таких изменений. Любая историческая информация на нашем веб-сайте предоставляется только для справки.</p>\n' +
  '\n' +
  '<h2>Дополнительные инструменты</h2>\n' +
  '<p>Обратите внимание, что наш веб-сайт может включать инструменты третьих сторон, которые находятся вне нашего контроля. Доступ к этим инструментам предоставляется на условиях «как есть» без гарантий, явных или подразумеваемых, и без каких-либо одобрений. Мы не несем ответственности за использование инструментов третьих сторон, предоставляемых на нашем веб-сайте. Вы несете полную ответственность за любые риски, связанные с использованием этих инструментов; мы рекомендуем вам ознакомиться с Условиями обслуживания соответствующего поставщика. dressdown.ai имеет право в будущем предлагать новые инструменты третьих сторон.</p>\n' +
  '\n' +
  '<h2>Ссылки</h2>\n' +
  '<p>dressdown.ai не проверял все сайты, связанные с его веб-сайтом, и не несет ответственности за содержание любого из таких связанных сайтов. Включение любой ссылки не подразумевает одобрение, утверждение или контроль dressdown.ai над сайтом. Использование любого такого связанного сайта происходит на ваш страх и риск, и мы настоятельно рекомендуем вам провести собственное расследование по поводу пригодности этих сайтов.</p>\n' +
  '\n' +
  '<h2>Возмещение убытков</h2>\n' +
  '<p>Вы соглашаетесь защищать, возмещать ущерб и освободить нас от любых убытков, повреждений, обязательств, претензий или требований, включая разумные гонорары адвокатов и расходы, предъявляемые любой третьей стороной в связи с: (1) вашими вкладами; (2) использованием веб-сайта; (3) нарушением этих Условий обслуживания; (4) любым нарушением ваших представлений и гарантий, изложенных в этих Условиях обслуживания; (5) вашим нарушением прав третьей стороны, включая, помимо прочего, права интеллектуальной собственности; или (6) любыми явными вредоносными действиями по отношению к любому другому пользователю веб-сайта, с которым вы связались через веб-сайт. Несмотря на вышеизложенное, мы оставляем за собой право, за ваш счет, принять на себя исключительную защиту и контроль над любым делом, по которому вы обязаны возместить ущерб, и вы соглашаетесь сотрудничать, за ваш счет, с нашей защитой таких претензий. Мы постараемся уведомить вас о любой такой претензии, действии или производстве, которое подпадает под это возмещение ущерба, по мере его появления.</p>\n' +
  '\n' +
  '<h2>Прекращение</h2>\n' +
  '<p>Мы можем приостановить или прекратить ваш аккаунт и доступ к веб-сайту и услугам немедленно, без предварительного уведомления или ответственности, по любой причине, включая, помимо прочего, нарушение Условий обслуживания. После прекращения ваше право использовать услуги немедленно прекратится.</p>\n' +
  '<p>Если вы хотите прекратить свой аккаунт, вы можете просто прекратить использование веб-сайта.</p>\n' +
  '<p>Все положения Условий обслуживания, которые по своей природе должны продолжать действовать после прекращения, будут продолжать действовать, включая, помимо прочего, положения о собственности, отказ от гарантий, возмещение ущерба и ограничения ответственности.</p>\n' +
  '\n' +
  '<h2>Разделяемость</h2>\n' +
  '<p>Если какое-либо положение этих Условий обслуживания будет признано неисполнимым или недействительным, такое положение будет изменено и интерпретировано с целью достижения целей такого положения в максимально возможной степени в соответствии с применимым законом, а оставшиеся положения останутся в полной силе и действии.</p>\n' +
  '\n' +
  '<h2>Изменения</h2>\n' +
  '<p>dressdown.ai оставляет за собой право по своему усмотрению изменять или заменять эти Условия обслуживания в любое время. Если пересмотр будет значительным, мы предоставим как минимум 30 дней уведомления до вступления в силу новых условий. Что представляет собой значительное изменение, будет определено по нашему усмотрению.</p>\n' +
  '<p>Продолжая использовать наши услуги после вступления в силу этих изменений, вы соглашаетесь соблюдать пересмотренные условия. Если вы не согласны с новыми условиями, вы больше не имеете права использовать dressdown.ai.</p>\n' +
  '\n' +
  '<h2>Полное соглашение</h2>\n' +
  '<p>Условия обслуживания вместе с нашей Политикой конфиденциальности и любыми другими юридическими уведомлениями или политиками, размещенными нами на веб-сайте, составляют полное соглашение между вами и dressdown.ai относительно вашего использования веб-сайта.</p>\n' +
  '\n' +
  '<h2>Применимое право</h2>\n' +
  '<p>Эти Условия обслуживания регулируются и толкуются в соответствии с законами Англии. Вы безоговорочно подчиняетесь исключительной юрисдикции судов в этом месте.</p>\n'

export default ded
